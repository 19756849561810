<template>
  <div class="form_wrap t2">
    <input
      class="wid120 inp_cal"
      type="text"
      :placeholder="$t('comp.012')"
      ref="dp"
      name="sdt"
      v-model="formattedSdt"
      @change="changeDt"
      @keydown="keyinput"
      maxlength="10"
    />
    <div class="input_calendar inline">
      <input
        class="inp_cal"
        type="text"
        name="edt"
        :placeholder="$t('comp.013')"
        v-model="formattedEdt"
        @click="showRangePicker"
        @change="changeDt"
        @keydown="keyinput"
        maxlength="10"
      />
      <button class="tbl_icon calendar" @mousedown.stop.prevent @click.stop.prevent="toggleRangePicker">{{ $t('comp.014') }}</button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DateRangePickerSimple',

  props: {
    sdate: { type: String, default: '', required: false },
    edate: { type: String, default: '', required: false },
    line: { type: Number, default: 0, required: false },
    isDebug: { type: Boolean, default: false, required: false }
  },

  data () {
    return {
      sdt: this.sdate,
      edt: this.edate,
      format: 'YYYY.MM.DD',
      show: false,
      idx: this.line
    }
  },

  computed: {
    formattedSdt: {
      set (v) {
        this.sdt = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.sdt.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      }
    },

    formattedEdt: {
      set (v) {
        this.edt = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.edt.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      }
    }
  },

  watch: {
    sdate () {
      this.sdt = this.sdate
      this.setDatePick()
    },
    edate () {
      this.edt = this.edate
      this.setDatePick()
    }
  },

  mounted () {
    $(this.$refs.dp).daterangepicker({
      autoUpdateInput: false,
      showDropdowns: true,
      autoApply: true,
      locale: {
        format: this.format
      },
      drops: 'auto'
    }, (s, e, l) => {
      if (e.diff(s, 'months', true) > 1) {
        this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.UTIL010G010.021'), useConfirmBtn: false })
        return
      }

      this.formattedSdt = s.format(this.format)
      this.formattedEdt = e.format(this.format)
      this.emitChange()
    })
    .on('show.daterangepicker', () => {
      this.setDatePick()
      this.show = true
    })
    .on('hide.daterangepicker', () => {
      this.show = false
    })
    this.setDatePick() // 최초 날짜 세팅
  },

  methods: {
    keyinput (e) {
      const dp = $(this.$refs.dp).data('daterangepicker')
      dp.clickCancel()
      dp.hide()
    },

    toggleRangePicker (e) {
      const dp = $(this.$refs.dp).data('daterangepicker')
      if (this.show) dp.hide()
      else dp.show()
    },

    showRangePicker () {
      const dp = $(this.$refs.dp).data('daterangepicker')
      dp.show()
    },

    setDatePick () {
      const format = 'YYYYMMDD'
      const dp = $(this.$refs.dp).data('daterangepicker')

      if (this.sdt && this.edt) {
        const smo = moment(this.sdt, format)
        if (smo.isValid()) dp.setStartDate(smo.toDate())

        const emo = moment(this.edt, format)
        if (emo.isValid()) dp.setEndDate(emo.toDate())
      }
    },

    emitChange () {
      this.$emit('change', this.sdt, this.edt, this.idx)
    },

    changeDt (e) {
      // e.preventDefault() // Enter Key 클릭시 daterangepicker 에서 호출하는 callback 차단(2번 호출 방지용)
      const t = e.target
      const n = t.name
      const m = t.value.match(/(\d+)\.(\d+)\.(\d+)/)
      t.value = t.oldValue ?? ''

      if (m) {
        const mo = moment({
          year: parseInt(m[1]),
          month: parseInt(m[2]) - 1,
          day: parseInt(m[3])
        })

        if (mo.isValid()) {
          this.setDatePick()

          const format = 'YYYYMMDD'
          const smo = moment(this.sdt, format)
          const emo = moment(this.edt, format)
          if (smo.isValid() && emo.isValid()) {
            const diffmon = emo.diff(smo, 'months', true)

            if (diffmon > 1 || diffmon < 0) {
              this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.UTIL010G010.021'), useConfirmBtn: false })
              return
            }
          }

          t.value = mo.format(this.format)
          t.oldValue = t.value
        }
      }

      if (n === 'sdt') {
        this.formattedSdt = t.value
      }

      if (n === 'edt') {
        this.formattedEdt = t.value
      }

      this.emitChange()
    }
  }
}
</script>

<style scoped>
.input_calendar.mid, .input_calendar.mid input {
    width: 200px;
}
div.input_error .inp_cal {
  border: 1px solid #f2084f !important;
}
</style>
